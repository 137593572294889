<template>
  <div class="container">
    <h4 class="text-center my-4">Мой профиль</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div class="row mt-3">
        <div class="col-md-2"><b>Имя пользователя</b></div>
        <div class="col-md-10"> {{user.username}}</div>
      </div>

      <div class="row mt-3">
        <div class="col-md-2"><b>ФИО</b></div>
        <div class="col-md-10">{{user.lastname}} {{user.firstname}}</div>
      </div>

      <div class="form-group row mt-3">
        <label for="email" class="col-md-2 col-form-label"><b>Email</b></label>
        <div class="col-md-4">
          <input id="email" type="email" class="form-control" placeholder="Email" v-model="user.email">
        </div>
      </div>

      <div class="form-group row mt-3" v-if="student">
        <label for="phone" class="col-md-2 col-form-label"><b>Телефон</b></label>
        <div class="col-md-4">
          <input id="phone" type="tel" class="form-control" placeholder="87071234567" v-model="student.phone">
        </div>
      </div>

      <div class="my-4">
        <Button icon="pi pi-save" label="Сохранить" :loading="changing" :disabled="user.email.length < 6"
                class="p-button-rounded"
                @click="changeEmail"/>
      </div>


    </div>

  </div>
</template>


<script>
  import userInfo from "../services/userInfo"
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
  export default {
    name: "Profile",

    data() {
      return {
        loading: true,
        user: null,
        changing: false,
        oldEmail: null,
        student: null,
        phone: null
      }
    },
    methods: {
      // checkRole(i) {
      //   const roles = JSON.parse(getCookie('ROLES')) || []
      //   if (!roles.length && getCookie('ROLE')) {
      //     roles.push(getCookie('ROLE'))
      //   }
      //   return roles.includes(i)
      // },
      async changeEmail() {
        if (this.user.email.length < 6 || !this.validateEmail(this.user.email)) {
          this.$error({text: 'Неверный email'})
          return
        }

        this.changing = true
        try {
          await userInfo.changeEmailAndPhone({
            email: this.user.email.trim(),
            phone: this.student?.phone
          })
          await this.setupData()
          this.$message({text: 'Данные успешно изменены'})
        } catch (e) {
          this.$error({text: 'Произошла ошибка'})
        }
        this.changing = false
      },
      validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
      },
      async setupData() {
        this.user = await userInfo.getUserInfo()
        if (decryptCheckRole('student')) {
          this.student = await userInfo.getStudentInfo()
        }
        this.user.email = this.user.email || ''
        this.oldEmail = this.user.email
      },

    },
    async mounted() {
      await this.setupData()
      this.loading = false
    }
  }

</script>
<style scoped>

</style>

